import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import dropdownImg from '../assets/images/drop-down.svg';

const DataOption = (props) => {
    return (
        <components.Option {...props}>
            <img className="language-flag-signup" src={props.data.image} alt={props.data.label} style={{ marginRight: '8px', width: 'auto', borderRadius: '50%', objectFit: 'cover', width: '38px', height: '38px' }} />
            <span className="language-span-signup"> {props.data.label}</span>
        </components.Option>
    );
};

const DataSingleValue = (props) => {
    return (
        <components.SingleValue {...props}>
            <img className="language-flag-signup selected" src={props.data.image} alt={props.data.label} style={{ marginRight: '8px', width: 'auto' }} />
            <span className="language-span-signup selected"> {props.data.label}</span>
        </components.SingleValue>
    );
};

const DataOptionReverse = (props) => {
    return (
        <components.Option {...props}>
            {props.data.label}
            <img className="language-flag-signup" src={props.data.image} alt={props.data.label} style={{ marginLeft: '8px', width: 'auto' }} />
        </components.Option>
    );
};

const DataSingleValueReverse = (props) => {
    return (
        <components.SingleValue {...props}>
            {props.data.label}
            <img className="language-flag-signup" src={props.data.image} alt={props.data.label} style={{ marginLeft: '8px', width: 'auto' }} />
        </components.SingleValue>
    );
};

const SelectList = ({ data, placeHolder, handleSelectChange, defaultSelectedOption, swipeLabel = false, isMultiSelect = false, hasRedBorder = false }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [hasValue, setHasValue] = useState(Boolean(null));
    useEffect(() => {
        setSelectedOption(defaultSelectedOption);
        setHasValue(Boolean(defaultSelectedOption));
    }, [defaultSelectedOption])

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: 8,
            border: `1px solid ${hasRedBorder ? (hasValue ? '#ccc' : 'red') : '#ccc'}`,
            boxShadow: 'none',
            cursor: 'pointer',
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? '#F4F3F3' : '#fff',
            color: state.isSelected ? '#40474d' : '#40474d',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#40474d',
            fontSize: '16px',
        }),
    };

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setHasValue(Boolean(selectedOption));
        handleSelectChange(selectedOption);
    };

    return (
        <>
            <Select
                options={data}
                components={{ Option: swipeLabel ? DataOptionReverse : DataOption, SingleValue: swipeLabel ? DataSingleValueReverse : DataSingleValue }}
                styles={customStyles}
                isSearchable={false}
                placeholder={placeHolder}
                value={selectedOption}
                onChange={handleChange}
                isMenuOpen={selectedOption !== null}
                menuPortalTarget={document.body}
                onClose={() => setSelectedOption(null)}
                isMulti={isMultiSelect}
            />
            <img className="rs-dropdownImg" src={dropdownImg} alt="" />
        </>
    )
}

export default SelectList;