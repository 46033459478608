import React, { useEffect, useState } from "react";
import useHttpService from "../../HttpService";
import plusImage from "../../assets/images/plus.svg";
import englishImage from "../../assets/images/english.svg";
import SelectList from "../SelectList";
import AddUpdateLesson from "./AddUpdateLesson";
import Pagination from "../Pagination";
import {
    difficultyData,
    countryData,
    levelDots,
    stateBackground,
    Status,
    MediaBaseURL
} from "../../HelperMethods";
import { toast } from "react-toastify";
import Tooltip from "../Tooltip";
import Select from "react-select";
import dropdownImg from "../../assets/images/drop-down.svg";
import ShowModal from "../ShowModal";
import ErrorMessage from "../../assets/images/error-mesgg.svg";

const Lessons = () => {
    const [lessons, setLessons] = useState(null);
    const [totalLessonCount, setTotalLessonCount] = useState(0);
    const [selectedLesson, setSelectedLesson] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedLevelId, setSelectedLevelId] = useState(0);
    const [selectedState, setSelectedState] = useState(["New", "Unpublished"]);
    const [selectedOption, setSelectedOption] = useState([Status[0], Status[2]]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [resetKey, setResetKey] = useState(false);
    const [clickedLessonId, setClickedLessonId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchText, setSearchText] = useState("");
    const httpService = useHttpService();
    const [languageId, setlanguageId] = useState(1);
    const [languages, setLanguages] = useState([]);
    const [defaultLanguageId, setDefaultLanguageId] = useState(null);
    const [clearResult, setClearResult] = useState(false);
    const handleLessonClick = (lesson) => {
        setSelectedLesson(lesson);
        setClickedLessonId(lesson.lessonId);
    };

    const pageItems = [6, 15, 30];
    const requestObj = {
        PageNumber: 1,
        PageSize: pageItems[0],
        SearchText: "",
        levelId: 0,
        state: "",
        Word: "",
        languageId: 1
    };

    useEffect(() => {
        getLanguages();
        getLessons(requestObj.PageNumber, requestObj.PageSize);
        if (clickedLessonId) {
            const selectIndex = lessons.findIndex(
                (l) => l.lessonId === clickedLessonId
            );
            const [selectedLesson] = lessons.splice(selectIndex, 1);
            setLessons([selectedLesson, ...lessons]);
        }
    }, [
        selectedLevelId,
        selectedState,
        resetKey,
        selectedOption,
        clickedLessonId,
    ]);

    useEffect(() => {
        getLessons(requestObj.PageNumber, requestObj.PageSize);
    }, [languageId]);

    const getLessons = (pageNumber, pageSize, text) => {
        requestObj.PageNumber = pageNumber;
        requestObj.PageSize = pageSize;
        requestObj.state = selectedState !== "" ? selectedState : null;
        requestObj.levelId =
            selectedLevelId !== undefined && selectedLevelId !== 0
                ? selectedLevelId
                : null;
        requestObj.SearchText = text === undefined ? searchText : text;
        requestObj.LanguageId = languageId ?? 0;
        httpService
            .post("/api/lessons/all", requestObj)
            .then((response) => {
                let updatedLessons = response.data.data;

                if (clickedLessonId) {
                    const selectedIndex = updatedLessons.findIndex(
                        (l) => l.lessonId === clickedLessonId
                    );
                    const [selectedLesson] = updatedLessons.splice(selectedIndex, 1);
                    updatedLessons = [selectedLesson, ...updatedLessons];
                }

                setLessons(updatedLessons);
                setTotalLessonCount(response.data.totalCount);
                setTotalCount(response.data.totalCount);
            })
            .catch((err) => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                    setShowModal(true);
                } else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                    setShowModal(true);
                } else {
                    setErrorMessage(
                        "Something went wrong, please try again or contact support!"
                    );
                    setShowModal(true);
                }
            });
    };

    const handleAddLesson = (event) => {
        event.preventDefault();
        setSelectedLesson({
            lessonId: 0,
            name: "",
            description: "",
            focus: "",
            levelId: 0,
            paragraph: "",
            state: "New",
            status: "New",
        });
    };

    const handleTitleChange = (event) => {
        setClearResult(true);
        requestObj.SearchText = event.target.value;
        setSearchText(event.target.value);
        getLessons(requestObj.PageNumber, requestObj.PageSize, requestObj.SearchText);
    };

    const handleLevelChange = (selectedOption) => {
        const selectedValues = [];
        setClearResult(true);
        for (let i = 0; i < selectedOption.length; i++) {
            const a = selectedOption[i].value;
            selectedValues.push(a);
        }
        if (selectedOption.length === 0) {
            setSelectedLevelId(0);
        } else {
            setSelectedLevelId(selectedValues);
        }
        getLessons(requestObj.PageNumber, requestObj.PageSize);
    };

    const handleWordChange = (event) => {
        requestObj.Word = event.target.value;
        getLessons(requestObj.PageNumber, requestObj.PageSize);
    };

    const handleStateChange = () => {
        const selectedValues = [];
        setClearResult(true);
        for (let i = 0; i < selectedOption.length; i++) {
            const a = selectedOption[i].value;
            selectedValues.push(a);
        }
        if (selectedOption.length === 0) {
            setSelectedState("");
        } else {
            setSelectedState(selectedValues);
        }
        getLessons(requestObj.PageNumber, requestObj.PageSize);
        setMenuIsOpen(false);
    };

    const handleCountryChange = (selectedOption) => {
        setClearResult(true);
        setlanguageId(selectedOption.value);
    };

    const handleRefreshLessons = (state) => {
        setClearResult(false);
        if (clickedLessonId && state === "Published") {
            const selectedIndex = lessons.findIndex(
                (l) => l.lessonId === clickedLessonId
            );
            const [selectedLesson] = lessons.splice(selectedIndex, 1);
            selectedLesson.state = "Published";
            setLessons([selectedLesson, ...lessons]);
        }
        else if (clickedLessonId && state === "Deleted") {
            const selectedIndex = lessons.findIndex(
                (l) => l.lessonId === clickedLessonId
            );
            const selectedLesson = lessons.splice(selectedIndex, 1);
            setLessons([...lessons]);
        }
        else {
            getLessons(requestObj.PageNumber, requestObj.PageSize);
        }
    };

    const handleClearFilters = (event) => {
        event.preventDefault();
        setSelectedOption([Status[0], Status[2]]);
        setSelectedState(["New", "Unpublished"]);
        setResetKey((prevResetKey) => !prevResetKey);
        setSelectedLevelId(0);
        requestObj.PageNumber = 1;
        requestObj.PageSize = pageItems[0];
        requestObj.SearchText = "";
        requestObj.levelId = 0;
        requestObj.Word = "";
        requestObj.languageId = 1;
        document.getElementById("Lessons").value = "";
        setSearchText("");
        setDefaultLanguageId(null);
        setlanguageId(null);
        setClearResult(false);
        getLessons(requestObj.PageNumber, requestObj.PageSize);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleUpdateLessonDetails = (lesson) => {
        const selectedIndex = lessons.findIndex(
            (l) => l.lessonId === lesson.lessonId
        );
        if (selectedIndex > -1) {
            const updatedLessons = [...lessons];
            const selectedLesson = updatedLessons[selectedIndex];
            selectedLesson.name = lesson.name;
            selectedLesson.description = lesson.description;
            selectedLesson.focus = lesson.focus;
            selectedLesson.paragraph = lesson.paragraph;
            selectedLesson.levelId = lesson.levelId;
            selectedLesson.status = lesson.status;
            setLessons(updatedLessons);
        } else {
            const newLessonList = [lesson, ...lessons];
            setLessons(newLessonList);
        }
    };

    const getLanguages = () => {
        httpService
            .get(`/api/languages/all`)
            .then((res) => {
                const transformedData = convertApiDataToOptions(res.data);
                setLanguages(transformedData);
                setDefaultLanguageId(1);
            })
            .catch((err) => {
                if (err?.response?.data) {
                    setErrorMessage(err.response.data);
                }
                else if (err.errors) {
                    setErrorMessage(err.errors[0]);
                }
                else {
                    setErrorMessage("Something went wrong, please try again or contact support!");
                }
                setShowModal(true);
                return;
            })
            .finally();
    };
    const convertApiDataToOptions = (apiData) => {
        return apiData.map((language) => ({
            value: String(language.languageId),
            label: language.name,
            image: `${MediaBaseURL}${language.flagPath}`,
        }));
    };

    return (
        <>
            <section className="dashbord-main dashbord-lessons-main lesson-admin-unique">
                <div className="container inner-container">
                    <div className="my-lessons">
                        <h2>Lessons Admin</h2>
                        <Tooltip text="Add Lessons">
                            <div className="dropdown my-lessons-dropdown leson-dashbord">
                                <a
                                    className="btn btn-secondary dropdown-toggle"
                                    href="/"
                                    onClick={(event) => handleAddLesson(event)}
                                >
                                    <img className="dasboard-plusico" src={plusImage} alt="" />
                                </a>
                            </div>
                        </Tooltip>
                    </div>
                    <div className="lessons-input-fielsMain">
                        <form>
                            <div className="lesson-forms">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    id="Lessons"
                                    className="form-control"
                                    onChange={handleTitleChange}
                                    placeholder="Search Lessons"
                                />
                            </div>
                            <div className="lesson-forms-selectors">
                                <SelectList
                                    isMultiSelect={true}
                                    data={difficultyData}
                                    placeHolder="Difficulty"
                                    handleSelectChange={handleLevelChange}
                                    swipeLabel={true}
                                    key={resetKey.toString()}
                                />
                            </div>
                            <div className="lesson-forms-selectors lesson-selector-two">
                                <Select
                                    style={{ minWidth: "236px !important" }}
                                    isMulti="true"
                                    options={Status}
                                    placeholder="Status"
                                    onChange={setSelectedOption}
                                    closeMenuOnSelect={false}
                                    onBlur={handleStateChange}
                                    value={selectedOption}
                                    onMenuOpen={() => setMenuIsOpen(true)}
                                    onMenuClose={() => setMenuIsOpen(false)}
                                    menuIsOpen={menuIsOpen}
                                    isSearchable={false}
                                />
                                <img className="rs-dropdownImg" src={dropdownImg} alt="" />
                            </div>
                            <div className="difficulty-dropdown header-dp new-language-selected">
                                <SelectList data={languages} placeHolder="Select Language" defaultSelectedOption={defaultLanguageId} handleSelectChange={handleCountryChange} className="form-select form-select-lg mb-3"></SelectList>
                            </div>
                            {clearResult && <div className="clear-filters">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        handleClearFilters(e);
                                    }}
                                >
                                    Clear Results
                                </a>
                            </div>}
                        </form>
                    </div>

                    <div className="lesson-title-main lesson-dashbord-main new-lesson-dashboard suggested-lesson-unique">
                        <div className="lesson-title-left lesson-dashbord-left">
                            {lessons && totalCount > 0 &&
                                lessons.map((lesson) => (
                                    <div
                                        className="lesson-title-box cursor"
                                        key={lesson.lessonId}
                                        onClick={() => {
                                            handleLessonClick(lesson);
                                        }}
                                    >
                                        {
                                            <>
                                                <div className="lesson-flag-main">
                                                    <div
                                                        className={`lesson-left-flag ${levelDots[lesson.levelId]}`}>
                                                        <h2>{lesson.name}</h2>
                                                        <div className="language-difficulty">
                                                            <Tooltip text="English">
                                                                <img src={englishImage} alt="" />
                                                            </Tooltip>
                                                            {[...Array(lesson.levelId)].map((_, index) => (
                                                                <span key={index}></span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="lesson-right-button">
                                                        <div
                                                            className={`lesson-link-button ${stateBackground[lesson.state]
                                                                }`}
                                                        >
                                                            <a href="/">{lesson.state}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lesson-description">
                                                    <span>
                                                        <label>Description:</label> {lesson.description}
                                                    </span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                ))}
                            {lessons && (
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div
                                            style={{
                                                color: "#40474D",
                                                fontSize: "12px",
                                                paddingTop: "28px",
                                            }}
                                        >
                                            Total Lessons: <strong>{totalLessonCount}</strong>
                                        </div>
                                    </div>
                                    <div className="col-sm-9">
                                        <Pagination
                                            pageNo={requestObj.PageNumber}
                                            size={requestObj.PageSize}
                                            count={totalCount}
                                            perPageItems={pageItems}
                                            onChange={getLessons}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {selectedLesson && (
                            <AddUpdateLesson
                                lesson={selectedLesson}
                                refreshLessons={handleRefreshLessons}
                                updateLessonDetails={handleUpdateLessonDetails}
                            />
                        )}
                    </div>
                </div>
            </section>
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    );
};

export default Lessons;
