import { set, useForm } from 'react-hook-form';
import { validateEmail, MediaBaseURL } from '../HelperMethods';
import useHttpService from '../HttpService';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import CustomModal from './CustomModal';
import LoadingSpinner from './LoadingSpinner';
import Home from './Home';
import { toast } from "react-toastify";
import eyeImage from '../assets/images/eye.svg'
import SelectList from './SelectList';
import ErrorMessage from "../assets/images/error-mesgg.svg";
import ShowModal from "../components/ShowModal";

const SignUp = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const httpService = useHttpService();
    const [languageId, setlanguageId] = useState('');
    const [languages, setLanguages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const getLanguages = () => {
            httpService
                .get(`/api/languages/all`)
                .then((res) => {
                    const transformedData = convertApiDataToOptions(res.data);

                    setLanguages(transformedData);
                })
                .catch((err) => {
                    if (err?.response?.data) {
                        setErrorMessage(err.response.data);
                    }
                    else if (err.errors) {
                        setErrorMessage(err.errors[0]);
                    }
                    else {
                        setErrorMessage("Something went wrong, please try again or contact support!");
                    }
                    setShowModal(true);
                    return;
                })
                .finally();
        };

        getLanguages();
    }, []);

    const convertApiDataToOptions = (apiData) => {
        return apiData.map((language) => ({
            value: String(language.languageId),
            label: language.name,
            image: `${MediaBaseURL}${language.flagPath}`,
        }));
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const closeWithIcon = () => {
        setIsModalOpen(false);
        navigate('/');
    };
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const navigate = useNavigate();
    const onSubmit = (data) => {
        if (languageId === '') {
            setErrorMessage('Please select a language');
            setShowModal(true);
            return;
        }
        var signUpObj = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            confirmPassword: data.confirmPassword,
            languageId: languageId
        };
        setIsLoading(true);
        httpService.post('/api/accounts/signup', signUpObj)
            .then(data => {
                toast.success('Sign up successful');
                navigate('/login');
            })
            .catch(err => {
                if (err?.response?.data)
                    setErrorMessage(err.response.data);
                else if (err.errors)
                    setErrorMessage(err.errors[0]);
                else
                    setErrorMessage('Something went wrong, please try again or contact support!');
                setShowModal(true);
                return;
            })
            .finally(() => setIsLoading(false));
    }
    const validateConfirmPassword = (value) => {
        if (value !== watch('password')) {
            return 'Passwords do not match';
        }
        return true;
    };
    const navigateTo = (path, event) => {
        event.preventDefault();
        closeModal();
        navigate(path);
    }
    const toggleShowPassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
    };

    const handleLanguagelChange = (event) => {
        const selectedLanguageId = event.target.value;
        setlanguageId(selectedLanguageId)
    }
    const handleSelectChange  = (selectedOption) => {
        setlanguageId(selectedOption.value)
    }
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Home />
            <CustomModal isOpen={isModalOpen} closeModal={closeModal} closeWithIcon={closeWithIcon}>
                <h2>Create New Account</h2>
                <div className="form-main">
                    <form className="contact-inform form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="create-new-pass">
                            <div className="form-control">
                                <input {...register('firstName', { required: true })} type="text" className="form-input" />
                                <label htmlFor="firstName" className="form-label">First Name</label>
                                {errors.firstName?.type === 'required' && <p className="text-danger">First Name is required!</p>}
                            </div>
                            <div className="form-control">
                                <input {...register('lastName', { required: true })} type="text" className="form-input" />
                                <label htmlFor="lastName" className="form-label">Last Name</label>
                                {errors.lastName?.type === 'required' && <p className="text-danger">Last Name is required!</p>}
                            </div>
                            <div className="form-control">
                                <input {...register('email', { required: true, validate: validateEmail })} type="text" className="form-input" />
                                <label htmlFor="email" className="form-label">Email</label>
                                {errors.email?.type === 'required' && <p className="text-danger">Email is required!</p>}
                                {errors.email?.type === 'validate' && <p className="text-danger">Email format is not correct!</p>}
                            </div>
                            <div className="form-control">
                                <input {...register('password', { required: true, minLength: 6 })} type={showPassword ? 'text' : 'password'} className="form-input" />
                                <label htmlFor="password" className="form-label">Password</label>
                                <a href="/" onClick={(event) => { event.preventDefault(); toggleShowPassword(); }}><img src={eyeImage} alt="" /></a>
                                {errors.password?.type === 'required' && <p className="text-danger">Password is required!</p>}
                                {errors.password?.type === 'minLength' && <p className="text-danger">Password must be at least 6 characters!</p>}
                            </div>
                            <div className="form-control">
                                <input {...register('confirmPassword', { required: true, validate: validateConfirmPassword })} type={showConfirmPassword ? 'text' : 'password'} className="form-input" />
                                <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                <a href="/" onClick={(event) => { event.preventDefault(); toggleShowConfirmPassword(); }}><img src={eyeImage} alt="" /></a>
                                {errors.confirmPassword?.type === 'required' && <p className="text-danger">Confirm Password is required!</p>}
                                {errors.confirmPassword?.type === 'validate' && <p className="text-danger">Passwords do not match!</p>}
                            </div>
                            <div className="lesson-forms-selectors dropdown-selector form-control index-flag-fix">
                                <SelectList data={languages.filter(language => language.value !== "1")} placeHolder='Native Language' handleSelectChange={handleSelectChange} className="form-select form-select-lg mb-3"></SelectList>
                                {errors.language?.type === 'required' && <p className="text-danger">Please select language</p>}
                            </div>
                            <button type="submit" className="btn login-button signup-button">Signup</button>
                        </div>
                    </form>
                </div>
                <p>Already have an account? <a href="/" onClick={(event) => navigateTo('/login', event)}>Login</a> here</p>
            </CustomModal>
            {isLoading && <LoadingSpinner />}
            {showModal && (
                <ShowModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    AddClass={"error-message-mainbox fade"}
                >
                    <div className="error-mesg-main">
                        <div className="del-box">
                            <img src={ErrorMessage}></img>
                            <h2>Error</h2>
                            <span>{errorMessage}</span>
                            <div className="continoue">
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    }}
                                >
                                    Continue
                                </a>
                            </div>
                        </div>
                    </div>
                </ShowModal>
            )}
        </>
    )
}
export default SignUp;